import axios from "axios";
import { notification } from "antd";
import { SignJWT } from "jose";
import { v1 as uuidv1 } from "uuid";
import { privateSecretKey } from "../utilities/constants";
import { BASE_URL } from "../utilities/constants";
import { parseJwt } from "utilities/helpers";
import { languageLable } from "utilities/helpers";

const METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

class API {
  isLoggedIn = false;
  userToken = null;
  token = null;
  isGuest = false;
  selectedLanguageData = {};

  constructor() {
    this.baseURL = BASE_URL;
  }

  async generateDeviceToken() {
    const alg = 'HS256'
    const secret = new TextEncoder().encode(privateSecretKey)
    const token = await new SignJWT(
      /*{
        appversion: "2.1",
        appversioncode: "22",
        aud: ["com.archisys.artis"],
        exp: Math.floor(Date.now() / 1000) + 24 * 60 * 60,
        iat: Date.now(),
        id: uuidv1(),
        iss: "com.archisys.biotech",
        manufacturer: "Xiaomi",
        model: "Redmi Note 5",
        notificationid: uuidv1(),
        os: "Android",
        osversion: "8.1.0",
        platform: "Mobile",
      },*/
      {
            aud: ['com.archisys.artis'],
            appversion: '2.1',
            appversioncode: '22',
            iat: Date.now(),
            id: uuidv1(),
            iss: 'com.archisys.biotech',
            manufacturer: window.navigator.vendor,
            model: window.navigator.vendor,
            notificationid: uuidv1(),
            os: "PWA",
            osversion: window.navigator.appVersion,
            platform: 'Web',
            exp: Math.floor(Date.now() / 1000) + 24 * 60 * 60,
        }
      ).setProtectedHeader({ alg }).sign(secret);
    localStorage.setItem("deviceToken", token);
    return token;
  }

  getAuthenticationInfo() {
    if (
      localStorage.getItem("isLoggedIn") &&
      localStorage.getItem("authToken")
    ) {
      this.isLoggedIn = true;
      this.userToken = localStorage.getItem("authToken");
    } else if (localStorage.getItem("authToken")) {
      this.userToken = localStorage.getItem("authToken");
    }

    if (localStorage.getItem("selectedLanguageData")) {
      this.selectedLanguageData = JSON.parse(
        localStorage.getItem("selectedLanguageData")
      );
    }
  }

  async setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    headers["Content-Type"] = "application/json";

    if (localStorage.getItem("deviceToken")) {
      headers["Device"] = localStorage.getItem("deviceToken");
    } else {
      headers["Device"] = await this.generateDeviceToken();
    }

    if (data) {
      if (data.isMultipart) {
        headers["Content-Type"] = "multipart/form-data";
      }

      if (data.headers) {
        for (let key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }

    if (!(data && data.skipAuth) && this.userToken) {
      headers["Authorization"] = `Bearer ${this.userToken}`;
    }

    return headers;
  }

  async api(method, url, data, customBaseURL) {
    return new Promise(async (resolve, reject) => {
      this.getAuthenticationInfo();

      let axiosConfig = {};
      axiosConfig.method = method;
      axiosConfig.url = customBaseURL ? customBaseURL : this.baseURL + url;
      axiosConfig.headers = await this.setHeaders(data);

      if (data) {
        if (data.params) axiosConfig.params = data.params;
        if (data.data) axiosConfig.data = data.data;
      }

      axios(axiosConfig)
        .then((response) => {
          if (response.data.ResponseException) {
            const { ValidationErrors, Message } =
              response.data.ResponseException;
            notification.error({
              message: languageLable(
                this.selectedLanguageData,
                ValidationErrors?.[0]?.Message || Message
              ),
            });
            return;
          }

          if (response.StatusCode >= 400 && response.StatusCode <= 500) {
            notification.error({
              message: languageLable(
                this.selectedLanguageData,
                response.ResponseException?.ExceptionMessage
              ),
            });
          } else {
            resolve(response.data);
          }
        })
        .catch(async (error) => {
          // Error handling remains the same
          let errorData = JSON.parse(JSON.stringify(error));
          let errorData500;
          if (error.response?.data) {
            errorData500 = JSON.parse(JSON.stringify(error.response?.data));
          }

            if (errorData?.message?.includes("401") || ((errorData500?.ResponseException?.ExceptionMessage ?? "Something went wrong! Refreshing the page, Please try again.").includes("The token is expired"))) {
            const authToken = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            if (!localStorage.getItem("authToken") && authToken) {
              fetch(`${BASE_URL}RefreshToken?expiredTokenString=${authToken}`, {
                method: "post",
                headers: {
                  Device:
                    localStorage.getItem("deviceToken") ||
                    (await this.generateDeviceToken()),
                },
              }).then(async (res) => {
                const content = await res.json();
                if (content.StatusCode === 200 && content.Result) {
                  localStorage.setItem("authToken", content.Result.Token);
                  const tokenData = parseJwt(content.Result.Token);
                  localStorage.setItem("userData", JSON.stringify(tokenData));
                  // window.location = "/";
                } else {
                  notification.info({
                    message: languageLable(
                      this.selectedLanguageData,
                        content?.ResponseException?.ExceptionMessage ?? "Something went wrong. Refreshing the page, Please try again."
                    ),
                  });
                  localStorage.clear();
                  // window.location = "/";
                }
              });
            }
          } else if (errorData500?.StatusCode === 500) {
            notification.error({
              message: languageLable(
                this.selectedLanguageData,
                  errorData500?.ResponseException?.ExceptionMessage ?? "Something went wrong! Refreshing the page, Please try again."
              ),
            });
          }
        });
    });
  }

  get(url, data) {
    return this.api(METHOD.GET, url, data);
  }

  post(url, data, customBaseURL) {
    return this.api(METHOD.POST, url, data, customBaseURL);
  }

  put(url, data) {
    return this.api(METHOD.PUT, url, data);
  }

  delete(url, data) {
    return this.api(METHOD.DELETE, url, data);
  }
}

export default API;
